import {
  // faCircleXmark,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Job } from '../../../model';
import ServerSidedPagination from '../../pagination/server-sided-pagination.component';
import SearchBar from '../../search-bar/search-bar-component';
import Filters from '../job-filters/job-filters.component';
import JobListing from '../job-listing/job-listing.component';
import './search-jobs.styles.scss';

interface RawJob extends Omit<Job, 'description'> {
  description: string | Record<string, string[] | string>;
}

const EMPTY_FILTERS = {
  location: [],
  jobCategory: [],
  typeOfWork: [],
  education: [],
  pay: [],
};

const SearchComponent = () => {
  const searchInput = useRef<HTMLInputElement>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState<{ [key: string]: string[] }>(
    EMPTY_FILTERS,
  );
  const [scrolled, setScrolled] = useState(false);
  const [displayCount, setDisplayCount] = useState(0);
  const [requestCount, setRequestCount] = useState(1); // Always keep at least 1 for requests
  const [locations, setLocations] = useState<string[]>([]); // Store available locations
  const sectionRef = useRef<HTMLElement>(null);

  // Fetch locations when component mounts
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/jobs/locations`;
        console.log('Fetching locations from:', apiUrl);
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(
            `Failed to fetch locations: ${response.status} ${response.statusText}`,
          );
        }

        const responseText = await response.text();
        console.log('Raw response text:', responseText);

        const data = responseText
          ? JSON.parse(responseText)
          : { locations: [] };
        setLocations(data.locations || []);
      } catch (error) {
        console.error('Error fetching locations:', error);
        setLocations([]);
      }
    };

    fetchLocations();
  }, []);

  const clearSearch = useCallback(() => {
    setSearchQuery('');
    searchInput.current?.focus();
  }, [searchQuery]);

  const fetchData = useCallback(
    async (startIdx: number, endIdx: number) => {
      try {
        // Calculate page and limit from startIdx and endIdx
        const limit = endIdx - startIdx;
        // Convert from 1-based to 0-based pagination
        const page = Math.floor(startIdx / limit);

        // Build query parameters
        const queryParams = new URLSearchParams();

        // Add pagination params
        queryParams.set('page', page.toString());
        queryParams.set('limit', limit.toString());

        // Add search query if not empty
        if (searchQuery) {
          queryParams.set('search', searchQuery);
        }

        // Add filters that have values
        Object.entries(filters).forEach(([key, values]) => {
          if (values.length > 0) {
            queryParams.set(key, values.join(','));
          }
        });

        console.log('Query params:', queryParams.toString());

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/jobs?${queryParams}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        console.log('Raw data from server:', data);

        // Transform job descriptions
        data.listings = data.listings.map((job: RawJob) => {
          console.log('Processing job:', job.title);

          try {
            // Parse the description string into an object if it's a string
            const descriptionObj =
              typeof job.description === 'string'
                ? JSON.parse(job.description)
                : job.description;

            console.log('Parsed description:', descriptionObj);

            // Transform the description object into our required format
            const transformedDescription = Object.entries(descriptionObj).map(
              ([title, sections]) => {
                console.log('Processing section:', title, sections);
                return {
                  title,
                  content: Array.isArray(sections)
                    ? sections.flat().join('\n')
                    : String(sections),
                };
              },
            );

            return {
              ...job,
              description: transformedDescription,
            };
          } catch (error) {
            console.error('Error processing job description:', error);
            return job;
          }
        });

        console.log('Final transformed listings:', data.listings);

        setDisplayCount(data.numListings || 0); // For display purposes
        setRequestCount(Math.max(1, data.numListings || 1)); // Keep at least 1 for requests
        return data.listings || [];
      } catch (error) {
        console.error('Error fetching jobs:', error);
        setDisplayCount(0);
        setRequestCount(1);
        return [];
      }
    },
    [searchQuery, filters],
  );

  const renderSearchResults = useMemo(() => {
    return (
      <>
        <ServerSidedPagination
          ids={Array.from({ length: requestCount }, (_, i) => i.toString())}
          queryKey={`all_jobs-${searchQuery}-${JSON.stringify(filters)}`}
          renderItem={(job: Job) => <JobListing key={job.id} job={job} />}
          fetchData={fetchData}
          onScroll={(ref) => {
            if (ref.current) {
              setScrolled(ref.current.scrollTop > 50);
            }
          }}
          itemsPerPage={15}
          scrolled={scrolled}
        />
      </>
    );
  }, [fetchData, searchQuery, filters, requestCount, scrolled]);

  return (
    <>
      <section
        className={'searchContainer ' + (scrolled ? 'scrolled' : '')}
        ref={sectionRef}>
        <div
          className="searchBarContainer"
          onClick={() => searchInput.current && searchInput.current.focus()}>
          <span className="search-icon">
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </span>
          <SearchBar
            searchQuery={searchQuery}
            onSearchQueryChange={setSearchQuery}
            clearSearch={clearSearch}
          />
        </div>
        <Filters
          onFilter={(filterParams: { [key: string]: string[] }) => {
            setFilters({ ...filters, ...filterParams });
          }}
          onClearFilters={() => {
            setFilters(EMPTY_FILTERS);
          }}
          locations={locations}
        />
        <p className="jobCount">
          {displayCount + ' '}
          Jobs Found
        </p>
      </section>
      {renderSearchResults}
    </>
  );
};

export default SearchComponent;
