import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore';

import firebaseApp from '../../firebaseConfig';
import { Job } from '../../model';
import { SavedJob, SavedJobSchema } from '../../model/zod/jobs.zod';
import { UserSchema } from '../../model/zod/user.zod';
import { encodeJobId } from '../../util/codec';

export const updateSavedJobs = async (job: Job) => {
  try {
    const jobRef = doc(firebaseApp.db, 'saved_jobs', encodeJobId(job.id));
    const jobSnap = await getDoc(jobRef);

    if (!jobSnap.exists()) {
      const newSavedJob = {
        ...job,
        savedAt: new Date(),
        jobCategory: job.jobCategory ?? '',
        typeOfWork: job.typeOfWork ?? '',
        timeCommitment: job.timeCommitment ?? '',
      };
      await setDoc(jobRef, newSavedJob);
    }
  } catch (error) {
    console.error('Failed to add Jobs', error);
  }
};

// Firebase batch calls can only get up to 10 items at a time
export const fetchSavedJobs = async (
  startIdx: number,
  endIdx: number,
  jobIdsSubset: string[],
): Promise<SavedJob[]> => {
  jobIdsSubset = jobIdsSubset.map(encodeJobId);
  const jobsRef = collection(firebaseApp.db, 'saved_jobs');
  const q = query(jobsRef, where('__name__', 'in', jobIdsSubset));
  const snapshot = await getDocs(q);

  // Map the snapshot documents to SavedJob objects while preserving the order
  const jobs: SavedJob[] = jobIdsSubset
    .map((id) => {
      const docSnapshot = snapshot.docs.find((d) => d.id === id);
      if (docSnapshot) {
        const jobData = docSnapshot.data();
        try {
          return SavedJobSchema.parse({
            ...jobData,
            savedAt: jobData.savedAt.toDate(),
          });
        } catch (error) {
          console.error('Error parsing job data', error);
          return undefined;
        }
      }
      return undefined;
    })
    .filter((job): job is SavedJob => job !== undefined);
  return jobs;
};

export const fetchJobsByUid = async (
  uid: string,
  type: 'savedJobs' | 'appliedJobs',
): Promise<string[]> => {
  try {
    const userRef = doc(firebaseApp.db, 'users', uid);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      const userData = docSnap.data();
      const parsedUser = UserSchema.parse({
        ...userData,
        createdAt: userData?.createdAt?.toDate(),
      });
      return parsedUser[type];
    } else {
      console.warn(`No user found with UID: ${uid}`);
      return [];
    }
  } catch (error) {
    console.error('Error fetching saved jobs by UID:', error);
    return [];
  }
};

export const fetchAttrByUid = async (
  uid: string,
  type: 'displayName',
): Promise<string> => {
  try {
    const userRef = doc(firebaseApp.db, 'users', uid);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      const userData = docSnap.data();
      const parsedUser = UserSchema.parse({
        ...userData,
        createdAt: userData?.createdAt?.toDate(),
      });
      return parsedUser[type] ?? '';
    } else {
      console.warn(`No user found with UID: ${uid}`);
      return '';
    }
  } catch (error) {
    console.error('Error fetching saved jobs by UID:', error);
    return '';
  }
};
