import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useEffect, useRef, useState } from 'react';

import { FiltersProps } from '../job-filters.component';
import '../job-filters.styles.scss';

export default function FilterLocation({
  onFilter,
  status,
  locations = [],
}: FiltersProps) {
  const [openMenu, setOpenMenu] = useState(false);
  const [checkedOptions, setCheckedOptions] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState('');
  const [filteredLocations, setFilteredLocations] = useState<string[]>([]);
  const menuRef = useRef<HTMLDivElement>(null);
  const submenuRef = useRef<HTMLDivElement>(null);

  // Update filtered locations when locations prop or search input changes
  useEffect(() => {
    let delay = 300;

    if (searchInput === '') {
      setFilteredLocations(locations);
      return;
    }
    if (searchInput.length > 3) {
      delay = 150;
    }

    const timeOutId = setTimeout(() => {
      const filtered: string[] = locations.filter((loc: string) => {
        return loc.toLowerCase().includes(searchInput.toLowerCase());
      });
      setFilteredLocations(filtered);
    }, delay);

    return () => {
      clearTimeout(timeOutId);
    };
  }, [searchInput, locations]);

  const handleClear = () => {
    setCheckedOptions([]);
    onFilter({ location: [] });
  };

  useEffect(() => {
    handleClear();
  }, [status]);

  // General useEffect for handling clicks outside the dropdown
  useEffect(() => {
    const handleDocumentClick = (e: MouseEvent) => {
      const menu = menuRef.current as HTMLElement | null;
      if (
        menu &&
        e.target &&
        !(e.target as Element).closest('.filter-dropdown.locations')
      ) {
        setOpenMenu(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  // logic for updating the list in jobBoard.tsx via updateList in services
  const handleCheck = (item: string) => {
    let checkedItems: string[] = checkedOptions;
    if (!checkedItems.includes(item)) {
      checkedItems.push(item);
    } else {
      const filteredItems = checkedItems.filter(
        (checkedItem) => checkedItem !== item,
      );
      checkedItems = filteredItems;
    }
    setCheckedOptions(checkedItems);

    if (checkedItems.length === 0) {
      onFilter({ location: [] });
      return;
    }

    onFilter({ location: checkedItems });
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchInput(value);
  };

  const handleClearSearch = () => {
    setSearchInput('');
  };

  const toggleOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    const menu = menuRef.current as HTMLElement | null;

    if (menu && e.target && !(e.target as Element).closest('.menuIcon')) {
      const menuPos = menu.getBoundingClientRect();

      if (menuPos && submenuRef.current) {
        submenuRef.current.style.left = menuPos.left + 'px';
      }
      setOpenMenu(!openMenu);
    }
  };

  return (
    <div className="filter-dropdown locations" ref={menuRef}>
      <div
        className={'filter-btn ' + (openMenu ? 'open' : '')}
        onClick={(e) => toggleOpen(e)}>
        <span className="filter-btn-title">
          {checkedOptions.length === 0
            ? 'Location'
            : 'Location (' + checkedOptions.length + ')'}
          <FontAwesomeIcon
            icon={openMenu ? faChevronUp : faChevronDown}
            className="dropdownIcon"
          />
        </span>
        <FontAwesomeIcon
          icon={faXmark}
          className={
            'menuIcon ' + (checkedOptions.length !== 0 ? 'active' : '')
          }
          onClick={() => handleClear()}
        />
      </div>
      <div
        className={'filter-items ' + (openMenu ? 'open' : '')}
        ref={submenuRef}>
        <div className="searchOptions">
          <input
            type="textbox"
            className="searchInput"
            value={searchInput}
            placeholder="Search by city..."
            onChange={(e) => handleInput(e)}></input>
          <FontAwesomeIcon
            icon={faXmark}
            className={`xIcon ${searchInput !== '' ? 'show' : ''}`}
            onClick={() => handleClearSearch()}
          />
        </div>
        <ul className="filterOptions">
          {filteredLocations.length === 0 ? (
            <li className="italicized">no options available...</li>
          ) : (
            filteredLocations
              .slice()
              .sort((a, b) => {
                // Compare whether a or b is in the checkedOptions array
                const aChecked = checkedOptions.includes(a);
                const bChecked = checkedOptions.includes(b);

                // Checked items should come first
                if (aChecked && !bChecked) return -1;
                if (!aChecked && bChecked) return 1;
                return 0; // If both are checked or both are not checked, their order remains unchanged
              })
              .map((item, index) => (
                <li className="filter-item" key={index}>
                  <input
                    type="checkbox"
                    id={`location=${index.toString()}`}
                    className="checkInput"
                    value={item}
                    checked={checkedOptions.includes(item) ? true : false}
                    onChange={() => handleCheck(item)}></input>
                  <label
                    htmlFor={`location=${index.toString()}`}
                    className="checkLabel">
                    <div className="checkBox">
                      <FontAwesomeIcon icon={faCheck} className="checkIcon" />
                    </div>
                    <span>{item}</span>
                  </label>
                </li>
              ))
          )}
        </ul>
      </div>
    </div>
  );
}
