import React, { useState } from 'react';

import FilterEducation from './job-filter-types/job-filters-education.component';
import FilterJobCategory from './job-filter-types/job-filters-job-category.component';
import FilterLocation from './job-filter-types/job-filters-location.component';
import FilterPay from './job-filter-types/job-filters-pay.component';
import FilterTypes from './job-filter-types/job-filters-types.component';
import './job-filters.styles.scss';

export interface FiltersProps {
  onFilter: (filters: { [key: string]: string[] }) => void;
  status: boolean;
  locations?: string[];
}

interface FilterParentProps {
  onFilter: (filters: { [key: string]: string[] }) => void;
  onClearFilters: () => void;
  locations?: string[];
}

export default function Filters({
  onFilter,
  onClearFilters,
  locations,
}: FilterParentProps) {
  const [resetStatus, setResetStatus] = useState<boolean>(false);
  const filterProps: FiltersProps = {
    onFilter,
    status: resetStatus,
    locations,
  };

  return (
    <div className="filtersContainer">
      {/* Filter components goes here corresponding to each div */}
      <FilterLocation {...filterProps} />
      <FilterJobCategory {...filterProps} />
      <FilterTypes {...filterProps} />
      <FilterEducation {...filterProps} />
      <FilterPay {...filterProps} />
      {/* Clear filters btn */}
      <button
        className="filter-btn reset"
        onClick={() => {
          setResetStatus((prev) => !prev);
          onClearFilters();
        }}>
        Clear Filters
      </button>
    </div>
  );
}
